@use "../../colors.scss";

.text {
    display: inline;
    align-items: flex-start;
    
    .link, .link:visited  {
        margin-left: 0.2em;
        color: colors.$black;
        text-decoration: none;
        border-bottom: dotted 1px colors.$black;
    }
}